import RenderMintableAmount from '@components/earn/RenderMintableAmount'
import RenderSwapOnlyTokens from '@components/shared/RenderSwapOnlyTokens'
import RenderTokenIcon from '@components/shared/RenderTokenIcon'
import { Table } from '@components/shared/Table'
import Tooltip from '@components/shared/Tooltip'
import commonStore from '@store/commonStore'
import { useCreatePoolStore } from '@store/permissionlessCreatePoolStore'
import priceStore from '@store/priceStore'
import useSettingsStore from '@store/settingsStore'
import { ColumnDef } from '@tanstack/react-table'
import StatsTooltipRow from 'components/StatsTooltip/StatsTooltipRow'
import { DEFAULT_CHAIN_ID } from 'config/chains'
import { TOKENS } from 'config/tokens'
import { getTotalGmInfo, useMarketTokensData } from 'domain/synthetics/markets'
import { useDaysConsideredInMarketsApr } from 'domain/synthetics/markets/useDaysConsideredInMarketsApr'
import { TokensData, convertToUsd } from 'domain/synthetics/tokens'
import { BigNumber } from 'ethers'
import { formatTokenAmount, formatUsd } from 'rfx/lib/numbers'
import useWallet from 'rfx/lib/wallets/useWallet'
import { calculateBonusAPR } from 'hooks/useBonusAPR'
import useBreakpoints from 'hooks/useBreakpoints'
import { PoolDataType } from 'hooks/usePerpsPoolData'
import useTotalTVLperCollateral from 'hooks/useTotalTVLperCollateral'
import { useRouter } from 'next/router'
import React, { useMemo } from 'react'
import { APRCell, calculateTotalAPR, getBoostandTotalTVLPool } from './APRCell'
import useSWR from 'swr'
import { keyBy } from 'lodash'

interface Props {
  marketTokensData: TokensData | undefined
  gmList: PoolDataType[]
}

const ZK_ALLOCATIONS = {
  ZK: 0,
  'wETH-USDC.e': 150_000,
  stETH: 0,
}

function GmList({ gmList }: Props) {
  const router = useRouter()
  const { active } = useWallet()
  const { below } = useBreakpoints()
  const isPro = useSettingsStore((state) => state.isPro())

  const pricesFromWS = priceStore((store) => store.prices)
  const pricesFromAPI = commonStore((store) => store.prices)

  const daysConsidered = useDaysConsideredInMarketsApr()
  const { setMarketDetailAddress, setOnPoolDetailsPage } = useCreatePoolStore()
  const { marketTokensData: marketTokensData } = useMarketTokensData({
    isDeposit: true,
  })
  const { data: merklData } = useSWR([`merkl-data`], {
    fetcher: async () => {
      try {
        const res = await fetch(`/merkl/v4/opportunities?name=RFX`)
        const data = await res.json()
        const aprs = keyBy(data, 'identifier')
        return aprs
      } catch (e) {
        return {}
      }
    },
    keepPreviousData: true,
  })

  const handlePoolClick = (address: string, tokenAddress: string) => {
    setMarketDetailAddress(address)
    setOnPoolDetailsPage(true)
    router.replace(
      {
        search: `?market=${tokenAddress}`,
      },
      undefined,
      {
        shallow: true,
      },
    )
  }

  const totalTVLgrouped = useTotalTVLperCollateral()

  const userTotalGmInfo = useMemo(() => {
    if (!active) {
      return
    }
    return getTotalGmInfo(marketTokensData)
  }, [marketTokensData, active])

  const calculateAPR = (market: PoolDataType) => {
    const { group, totalTVLPool } = getBoostandTotalTVLPool(
      market,
      totalTVLgrouped,
    )

    const zkToken = TOKENS[DEFAULT_CHAIN_ID].find(
      (item) => item.symbol === 'ZK',
    )

    let priceFromWS = pricesFromWS[zkToken?.pythId || '']?.['maxPrice']
    let priceFromAPI = pricesFromAPI[zkToken?.address || '']?.['maxPrice']

    const totalAlloc = ZK_ALLOCATIONS[group]

    const bonusAPR = calculateBonusAPR(
      totalAlloc,
      priceFromWS || priceFromAPI,
      totalTVLPool,
    )

    return calculateTotalAPR(market, bonusAPR)
  }

  const columns: ColumnDef<PoolDataType>[] = useMemo(
    () => [
      {
        id: 'market',
        accessorKey: 'marketIndexName',
        header: 'Market',
        meta: {
          align: 'left',
        },
        cell: ({ row }) => {
          const market = row.original
          return (
            <div className="flex items-center gap-2">
              {!isPro && (
                <div className="order-2 lg:order-1">
                  {market?.isSpotOnly ? (
                    <RenderSwapOnlyTokens
                      indexTokenSymbol={market.indexToken.symbol}
                      marketPoolName={market.marketPoolName}
                      size={below.lg ? 16 : 20}
                    />
                  ) : (
                    <RenderTokenIcon
                      symbol={market.indexToken.symbol}
                      size={below.lg ? 'small' : 'regular'}
                    />
                  )}
                </div>
              )}

              <div className="order-1 text-sm font-semibold text-th-fgd-1 lg:order-2">
                <div>{market.marketIndexName}</div>
              </div>
            </div>
          )
        },
      },
      {
        accessorKey: 'Collateral',
        header: 'Collateral',
        meta: {
          align: 'left',
        },
        cell: ({ row }) => {
          const market = row.original
          return (
            <div className="flex items-center gap-2">
              <div className="order-1 text-sm font-semibold text-th-fgd-1 lg:order-2">
                <div className="font-medium text-th-fgd-1">
                  {market.marketPoolName}
                </div>
              </div>
            </div>
          )
        },
      },
      {
        id: 'price',
        accessorKey: 'price',
        header: 'Price',
        meta: {
          align: 'left',
        },
        cell: ({ row }) => {
          const market = row.original
          return (
            <div className="text-sm font-medium text-th-fgd-1">
              {formatUsd(market.price, {
                displayDecimals: 3,
              })}
            </div>
          )
        },
        sortingFn: (rowA, rowB) => {
          const priceA = rowA.original.price
          const priceB = rowB.original.price

          return priceA.lt(priceB) ? 1 : priceA.gt(priceB) ? -1 : 0
        },
      },
      {
        id: 'totalSupply',
        accessorKey: 'totalSupply',
        header: 'Total Supply',
        meta: {
          align: 'left',
        },
        cell: ({ row }) => {
          const market = row.original

          return (
            <div className="flex flex-col text-sm font-medium text-th-fgd-1">
              <span>
                {formatTokenAmount(
                  market.totalSupply,
                  market.token.decimals,
                  'RP',
                  {
                    useCommas: true,
                    displayDecimals: 2,
                  },
                )}
              </span>
              {isPro && (
                <span className="text-th-fgd-3">
                  ({formatUsd(market.totalSupplyUsd)})
                </span>
              )}
            </div>
          )
        },
        sortingFn: (rowA, rowB) => {
          const totalSupplyA = rowA.original.totalSupply || BigNumber.from(0)
          const totalSupplyB = rowB.original.totalSupply || BigNumber.from(0)

          return totalSupplyA.lt(totalSupplyB)
            ? 1
            : totalSupplyA.gt(totalSupplyB)
              ? -1
              : 0
        },
      },
      {
        id: 'mintableAmount',
        accessorKey: 'mintableAmount',
        header: () => (
          <Tooltip
            className="text-none"
            placement="bottom"
            content={
              <p className="text-th-fgd-1">
                Available amount to deposit into the specific RP pool.
              </p>
            }
          >
            BUYABLE
          </Tooltip>
        ),
        meta: {
          align: 'left',
        },
        cell: ({ row }) => {
          const market = row.original
          return (
            <RenderMintableAmount
              mintableInfo={market.mintableInfo}
              market={market.market}
              token={market.token}
              longToken={market.longToken}
              shortToken={market.shortToken}
            />
          )
        },
        sortingFn: (rowA, rowB) => {
          const mintableAmountA =
            rowA.original.mintableAmount || BigNumber.from(0)
          const mintableAmountB =
            rowB.original.mintableAmount || BigNumber.from(0)

          return mintableAmountA.lt(mintableAmountB)
            ? 1
            : mintableAmountA.gt(mintableAmountB)
              ? -1
              : 0
        },
      },
      {
        id: 'balance',
        accessorKey: 'balance',
        header: () =>
          userTotalGmInfo ? (
            <Tooltip
              className="text-none"
              placement="bottom"
              content={
                <StatsTooltipRow
                  label={`Total in Wallet`}
                  value={[
                    formatTokenAmount(userTotalGmInfo?.balance, 18, 'RP', {
                      useCommas: true,
                      fallbackToZero: true,
                    }),
                    `(${formatUsd(userTotalGmInfo?.balanceUsd)})`,
                  ]}
                  showDollar={false}
                />
              }
            >
              WALLET
            </Tooltip>
          ) : (
            'WALLET'
          ),
        meta: {
          align: 'left',
        },
        cell: ({ row }) => {
          const market = row.original
          return (
            <div className="flex flex-col text-sm font-medium text-th-fgd-1">
              <span>
                {formatTokenAmount(
                  market.balance,
                  market.token.decimals,
                  'RP',
                  {
                    useCommas: true,
                    displayDecimals: 2,
                    fallbackToZero: true,
                  },
                )}
              </span>
              <span className="text-th-fgd-3">
                (
                {formatUsd(
                  convertToUsd(
                    market.balance,
                    market.token.decimals,
                    market.price,
                  ),
                  {
                    fallbackToZero: true,
                  },
                ) || '...'}
                )
              </span>
            </div>
          )
        },
        sortingFn: (rowA, rowB) => {
          const balanceA = rowA.original.balance || BigNumber.from(0)
          const balanceB = rowB.original.balance || BigNumber.from(0)

          return balanceA.lt(balanceB) ? 1 : balanceA.gt(balanceB) ? -1 : 0
        },
      },
      {
        id: 'apr',
        accessorKey: 'apr',
        header: () => (
          <Tooltip
            className="text-none"
            placement="bottom"
            showUnderline={false}
            content={
              <div className="text-th-fgd-1">
                <p>
                  APR is based on the Fees collected for the past{' '}
                  {daysConsidered} days. It is an estimate as actual Fees are
                  auto-compounded into the pool in real-time.
                </p>
                <p>
                  Check Pools performance against other LP Positions in
                  Dashboard.
                </p>
              </div>
            }
          >
            <p className="w-full border-b-0 border-dashed border-th-fgd-3 pb-0.5 text-start text-xxs font-normal text-th-fgd-2 lg:border-b lg:!text-xs">
              APR
            </p>
          </Tooltip>
        ),
        meta: {
          align: 'left',
        },
        cell: ({ row }) => {
          const market = row.original

          return (
            <APRCell
              market={market}
              totalTVLgrouped={totalTVLgrouped}
              merklData={merklData}
              className="max-[1280px]:!text-xs"
            />
          )
        },
        sortingFn: (rowA, rowB) => {
          const aprA = calculateAPR(rowA.original)
          const aprB = calculateAPR(rowB.original)

          return aprA.lt(aprB) ? 1 : aprA.gt(aprB) ? -1 : 0
        },
      },
    ],
    [merklData],
  )

  return (
    <Table
      colSpanColumns={{ 0: 2 }}
      columns={columns}
      rows={gmList || []}
      handleRowClick={(row) =>
        handlePoolClick(row.market?.marketTokenAddress || '', row.token.address)
      }
      rowClassName={isPro ? 'h-[48px]' : ''}
      mobileVisibleColumnsIds={['market', 'apr', 'price']}
      mobileHiddenColumnsIds={['totalSupply', 'mintableAmount', 'balance']}
      colsToRightAlign={['apr', 'price', 'mintableAmount', 'balance']}
    />
  )
}

export default React.memo(GmList)
